//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ConfirmationDialog",
  props: {
    show: Boolean,
    message: String,
    title: String,
    loading: Boolean,
    txWeight: String,
    btnMessage: String
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    onSubmit() {
      this.$emit("click");
    }
  }
};