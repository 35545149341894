//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { alertIcon } from "@debionetwork/ui-icons";
export default {
  name: "WarningDialog",
  data: () => ({
    alertIcon
  }),
  props: {
    show: Boolean,
    title: {
      type: String,
      default: "Title"
    },
    btnMessage: {
      type: String,
      default: "Submit"
    },
    message: {
      type: String,
      default: ""
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    onSubmit() {
      this.$emit("submit");
    }
  }
};