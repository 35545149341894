//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkCircle from "@/assets/check-circle-primary.png";
export default {
  name: "SuccessDialog",
  data: () => ({
    checkCircle
  }),
  props: {
    show: Boolean,
    title: {
      type: String,
      default: "Title"
    },
    message: {
      type: String,
      default: ""
    },
    buttonTitle: {
      type: String,
      default: "OK"
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    onSubmit() {
      this.$emit("submit");
    }
  }
};