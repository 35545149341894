import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import { u8aToHex } from "@polkadot/util";
import { updateGeneticAnalyst, updateGeneticAnalystAvailabilityStatus, unstakeGeneticAnalyst, queryGeneticAnalystByAccountId, queryGeneticAnalystQualificationsByHashId, updateQualification, unstakeGeneticAnalystFee, createQualificationFee, registerGeneticAnalystFee } from "@debionetwork/polkadot-provider";
import { uploadFile, getFileUrl, getIpfsMetaData } from "@/common/lib/pinata-proxy";
import { getSpecializationCategory, GAGetOrders } from "@/common/lib/api";
import { fileTextIcon, pencilIcon, trashIcon } from "@debionetwork/ui-icons";
import { mapState } from "vuex";
import { validateForms } from "@/common/lib/validate";
import localStorage from "@/common/lib/local-storage";
import rulesHandler from "@/common/constants/rules";
import errorMessage from "@/common/constants/error-messages";
import SuccessDialog from "@/common/components/Dialog/SuccessDialogGeneral";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import WarningDialog from "@/common/components/Dialog/WarningDialog";
import errorMessages from "@/common/constants/error-messages";
const initialData = {
  title: "",
  issuer: "",
  month: "",
  year: "",
  description: "",
  file: null,
  supportingDocument: null /* eslint-disable camelcase */
};

const imageType = ["image/jpg", "image/png", "image/jpeg"];
export default {
  name: "GAAccount",
  mixins: [validateForms],
  components: {
    SuccessDialog,
    ConfirmationDialog,
    WarningDialog
  },
  data: () => ({
    fileTextIcon,
    pencilIcon,
    trashIcon,
    publicKey: null,
    secretKey: null,
    showModalConfirm: null,
    isSuccess: false,
    error: false,
    errorDoc: false,
    isProfileLoading: false,
    showModal: false,
    showUnstakeDialog: false,
    isEdit: false,
    cardBlock: false,
    clearFile: false,
    loadingDoc: false,
    isLoading: false,
    loadingAvailability: false,
    unstakeLoading: false,
    showActiveOrder: false,
    hasActiveOrder: false,
    orderLists: [],
    profile: {
      profileImage: "",
      firstName: "",
      lastName: "",
      gender: "",
      dateOfBirth: null,
      email: "",
      phoneNumber: "",
      profileLink: "",
      specialization: null,
      specifyOther: "",
      availabilityStatus: "",
      experiences: [{
        title: ""
      }],
      certification: [],
      qualificationId: null
    },
    document: {
      ...initialData
    },
    stakingStatus: "",
    errorProfile: "",
    countries: [],
    categories: [],
    certificate: null,
    editId: null,
    txWeight: null,
    unstakeTxWeight: null,
    stakeAmount: "",
    selectMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  }),
  computed: {
    ...mapState({
      mnemonicData: state => state.substrate.mnemonicData,
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3,
      wallet: state => state.substrate.wallet
    }),
    computeAvatar() {
      return this.profile.profileImage ? this.profile.profileImage : require("@/assets/image-placeholder.png");
    },
    selectYears() {
      const years = [];
      const thisYear = new Date().getFullYear();
      for (let i = thisYear; i >= 1900; i--) {
        years.push(String(i));
      }
      return years;
    }
  },
  watch: {
    mnemonicData(val) {
      if (val) this.initialDataKey();
    }
  },
  rules: {
    profile: {
      name: [rulesHandler.FIELD_REQUIRED, rulesHandler.MAX_CHARACTER(50), rulesHandler.ENGLISH_ALPHABET],
      experience: [rulesHandler.FIELD_REQUIRED, rulesHandler.MAX_CHARACTER(100), rulesHandler.ENGLISH_ALPHABET],
      date: [rulesHandler.FIELD_REQUIRED],
      email: [rulesHandler.FIELD_REQUIRED, rulesHandler.EMAIL, rulesHandler.MAX_CHARACTER(255), rulesHandler.ENGLISH_ALPHABET],
      phoneNumber: [rulesHandler.FIELD_REQUIRED, val => /^[0-9]+$/.test(val) || "Phone number is invalid", rulesHandler.MAX_CHARACTER(12)],
      profileImage: [rulesHandler.FIELD_REQUIRED, rulesHandler.FILE_SIZE(2000000), rulesHandler.DEFAULT_IMAGE],
      profileLink: [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(255), rulesHandler.WEBSITE]
    },
    document: {
      title: [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(50)],
      issuer: [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(50)],
      month: [val => !!val || errorMessage.REQUIRED],
      year: [val => !!val || errorMessage.REQUIRED],
      description: [rulesHandler.MAX_CHARACTER(255), rulesHandler.ENGLISH_ALPHABET],
      file: [rulesHandler.FIELD_REQUIRED, rulesHandler.FILE_SIZE(2000000), rulesHandler.DEFAULT_IMAGE && rulesHandler.DEFAULT_ACCEPT_DOCUMENTS]
    }
  },
  async created() {
    if (this.mnemonicData) this.initialDataKey();
    this.isLoading = true;
    await this.getAccountData();
    await this.getSpecialization();
    await this.getActiveOrders();
    await this.getTxWeight();
    this.isLoading = false;
  },
  methods: {
    initialDataKey() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
    },
    async getActiveOrders() {
      var _orders$data;
      const orders = await GAGetOrders();
      this.hasActiveOrder = orders === null || orders === void 0 ? void 0 : (_orders$data = orders.data) === null || _orders$data === void 0 ? void 0 : _orders$data.some(order => order._source.status === "Paid");
    },
    async getTxWeight() {
      const qualification = {
        experience: [],
        certification: []
      };
      const getTxWeightProfile = await registerGeneticAnalystFee(this.api, this.wallet, this.profile);
      const getTxWeightQualification = await createQualificationFee(this.api, this.wallet, qualification);
      const getTxWeightUnstake = await unstakeGeneticAnalystFee(this.api, this.wallet);
      const txWeightProfile = "".concat(this.web3.utils.fromWei(String(getTxWeightProfile.partialFee), "ether"));
      const txWeightQualification = "".concat(this.web3.utils.fromWei(String(getTxWeightQualification.partialFee), "ether"));
      const txWeightUnstake = "".concat(this.web3.utils.fromWei(String(getTxWeightUnstake.partialFee), "ether"));
      const txWeight = Number(txWeightProfile) + Number(txWeightQualification);
      this.txWeight = txWeight;
      this.unstakeTxWeight = txWeightUnstake;
    },
    async getSpecialization() {
      const categories = await getSpecializationCategory();
      this.categories = categories;
    },
    async getAccountData() {
      const accountId = localStorage.getAddress();
      let profileData = this.profile;
      const analystData = await queryGeneticAnalystByAccountId(this.api, accountId);
      if (analystData) {
        var _analystData$info, _analystData$info2, _analystData$info3, _analystData$info4, _analystData$info5, _analystData$info6, _analystData$info7, _analystData$info8, _analystData$stakeAmo;
        profileData = {
          ...profileData,
          profileImage: analystData === null || analystData === void 0 ? void 0 : (_analystData$info = analystData.info) === null || _analystData$info === void 0 ? void 0 : _analystData$info.profileImage,
          firstName: analystData === null || analystData === void 0 ? void 0 : (_analystData$info2 = analystData.info) === null || _analystData$info2 === void 0 ? void 0 : _analystData$info2.firstName,
          lastName: analystData === null || analystData === void 0 ? void 0 : (_analystData$info3 = analystData.info) === null || _analystData$info3 === void 0 ? void 0 : _analystData$info3.lastName,
          gender: analystData === null || analystData === void 0 ? void 0 : (_analystData$info4 = analystData.info) === null || _analystData$info4 === void 0 ? void 0 : _analystData$info4.gender,
          email: analystData === null || analystData === void 0 ? void 0 : (_analystData$info5 = analystData.info) === null || _analystData$info5 === void 0 ? void 0 : _analystData$info5.email,
          phoneNumber: analystData === null || analystData === void 0 ? void 0 : (_analystData$info6 = analystData.info) === null || _analystData$info6 === void 0 ? void 0 : _analystData$info6.phoneNumber,
          specialization: analystData === null || analystData === void 0 ? void 0 : (_analystData$info7 = analystData.info) === null || _analystData$info7 === void 0 ? void 0 : _analystData$info7.specialization,
          availabilityStatus: analystData === null || analystData === void 0 ? void 0 : analystData.availabilityStatus,
          profileLink: analystData === null || analystData === void 0 ? void 0 : (_analystData$info8 = analystData.info) === null || _analystData$info8 === void 0 ? void 0 : _analystData$info8.profileLink
        };
        const dateOfBirth = analystData.info.dateOfBirth.replaceAll(",", "");
        const _dateOfBirth = new Date(+dateOfBirth).toLocaleString("fr-CA", {
          day: "numeric",
          year: "numeric",
          month: "numeric"
        });
        const stakeAmount = String(this.web3.utils.fromWei(String((analystData === null || analystData === void 0 ? void 0 : (_analystData$stakeAmo = analystData.stakeAmount) === null || _analystData$stakeAmo === void 0 ? void 0 : _analystData$stakeAmo.replaceAll(",", "")) || 0), "ether"));
        this.profile = profileData;
        this.profile.dateOfBirth = _dateOfBirth;
        this.stakingStatus = analystData === null || analystData === void 0 ? void 0 : analystData.stakeStatus;
        this.stakeAmount = "".concat(stakeAmount, " DBIO");
        if (analystData.qualifications.length) {
          const qualificationId = analystData.qualifications[0];
          const qualification = await queryGeneticAnalystQualificationsByHashId(this.api, qualificationId);
          this.profile.qualificationId = qualificationId;
          if (qualification.info.experience.length) {
            this.profile.experiences = qualification.info.experience;
          }
          if (qualification.info.certification.length) {
            let certifications = [];
            for (const cert of qualification.info.certification) {
              var _cert$supportingDocum, _rows$0$metadata$name;
              const {
                rows
              } = await getIpfsMetaData((_cert$supportingDocum = cert.supportingDocument) === null || _cert$supportingDocum === void 0 ? void 0 : _cert$supportingDocum.split("/").pop());
              const _certificate = {
                ...cert,
                file: {
                  name: (_rows$0$metadata$name = rows[0].metadata.name) !== null && _rows$0$metadata$name !== void 0 ? _rows$0$metadata$name : "Supporting Document File"
                }
              };
              certifications.push(_certificate);
            }
            this.profile.certification = certifications;
          }
        }
      }
    },
    onCloseModalDocument() {
      this.showModal = false;
      this.clearFile = true;
      this.errorDoc = false;
      this.editId = null;
      this.isEdit = false;
      this.document = {
        ...initialData
      };
    },
    async onSubmitFile() {
      this._touchForms("document");
      const {
        title,
        issuer,
        month,
        year,
        description,
        file,
        supportingDocument
      } = this.document;
      const doc = file;
      if (!title || !issuer || !month || !year) {
        this.errorDoc = true;
        return;
      }
      const document = {
        title,
        issuer,
        month,
        year,
        description,
        file: doc
      };
      if (this.editId != null) {
        this.profile.certification[this.editId] = {
          ...document,
          supportingDocument
        };
      } else {
        if (!doc) {
          this.errorDoc = true;
          return;
        }
        this.loadingDoc = true;
        const dataFile = await this.setupFileReader(doc);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const linkFile = getFileUrl(result.IpfsHash);
        this.profile.certification.push({
          ...document,
          supportingDocument: linkFile
        });
      }
      this.loadingDoc = false;
      this.onCloseModalDocument();
    },
    handleAddFile() {
      this.showModal = true;
      this.isEdit = false;
      this.clearFile = false;
    },
    deleteFile(idx) {
      this.showModalConfirm = null;
      const files = this.profile.certification;
      files.splice(idx, 1);
      this.profile.certification = files;
    },
    onEditFile(file, index) {
      this.document = {
        ...file
      };
      this.editId = index;
      this.isEdit = true;
      this.clearFile = true;
      this.showModal = true;
    },
    async handleAvailability(value) {
      this.loadingAvailability = true;
      if (value === "Unavailable" && this.hasActiveOrder) {
        this.showActiveOrder = true;
        return;
      }
      this.profile.availabilityStatus = value;
      try {
        await updateGeneticAnalystAvailabilityStatus(this.api, this.wallet, value);
        this.loadingAvailability = false;
      } catch (error) {
        console.error(error);
        this.loadingAvailability = false;
      }
    },
    async handleUnstake() {
      this.unstakeLoading = true;
      this.showUnstakeDialog = false;
      try {
        await unstakeGeneticAnalyst(this.api, this.wallet);
        this.getAccountData();
        this.unstakeLoading = false;
      } catch (error) {
        console.error(error);
        this.unstakeLoading = false;
      }
    },
    onUnstake() {
      if (this.hasActiveOrder) this.showActiveOrder = true;else this.showUnstakeDialog = true;
    },
    addExperience() {
      const experiences = this.profile.experiences;
      experiences.push({
        title: ""
      });
      this.profile.experiences = experiences;
    },
    removeExperience(idx) {
      const experiences = this.profile.experiences;
      experiences.splice(idx, 1);
      this.profile.experiences = experiences;
    },
    async handleSave() {
      this._touchForms("profile");
      const {
        profileImage,
        firstName,
        lastName,
        gender,
        dateOfBirth,
        email,
        phoneNumber,
        specialization,
        specifyOther,
        experiences,
        certification,
        profileLink
      } = this.profile;
      const _dateOfBirth = new Date(dateOfBirth).getTime();
      const experienceValidation = experiences.length === 1 && experiences.find(value => value.title === "");
      const certificateValidation = certification.length > 0 && certification.find(value => !value.supportingDocument);
      const _specialization = specialization == "Other" ? specifyOther : specialization;
      const _experiences = experiences.filter(value => value != "");
      const qualification = {
        experience: _experiences,
        certification: certification
      };
      if (!profileImage || !firstName || !lastName || !gender || !dateOfBirth || !email || !phoneNumber || !_specialization || experienceValidation || certificateValidation || !profileLink) {
        return this.error = true;
      }
      this.isLoading = true;
      try {
        await updateGeneticAnalyst(this.api, this.wallet, {
          boxPublicKey: this.publicKey,
          firstName,
          lastName,
          gender,
          dateOfBirth: _dateOfBirth,
          email,
          phoneNumber,
          specialization: _specialization,
          profileImage,
          profileLink
        });
        await updateQualification(this.api, this.wallet, this.profile.qualificationId, qualification);
        this.isSuccess = true;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    handleChooseFile() {
      if (this.$refs["input-file"]) this.$refs["input-file"].click();
    },
    async handleFileChange(event) {
      if (!event.target.value) return;
      const file = event.target.files[0];
      if (!imageType.includes(file.type)) return this.errorProfile = errorMessages.FILE_FORMAT("PNG/JPG");
      // if (file.type != "image/jpg" && file.type != "image/png") return this.errorProfile = errorMessages.FILE_FORMAT("PNG/JPG")
      else if (file.size > 2000000) return this.errorProfile = errorMessages.FILE_SIZE(2);
      this.isProfileLoading = true;
      const dataFile = await this.setupFileReader(file);
      const result = await uploadFile({
        title: dataFile.name,
        type: dataFile.type,
        size: dataFile.size,
        file: dataFile
      });
      const link = getFileUrl(result.IpfsHash);
      this.profile.profileImage = link;
      this.isProfileLoading = false;
      this.errorProfile = "";
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    limitChar(value) {
      return value.length > 32 ? "".concat(value.substring(0, 32), "...") : value;
    },
    goToDashboard() {
      this.$router.push({
        name: "ga-dashboard"
      });
    }
  }
};